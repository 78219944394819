import React, { createRef, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import logo from '../../../../assets/icons/rzero-logo-pad.svg'
import eqipment from '../../../../assets/images/eqipment.png'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  goToSummary,
  withMediaQuery,
} from '../../../../services/root/root-service'
import {
  BUTTONS_LABEL,
  HELPER_TEXT,
  LINKS,
  LOGIN_LABELS,
  ERRORS,
  AUTH_IS_IN_PROGRESS,
} from '../../services/authorization-constants'
import styles from './style'
import { trim } from 'lodash'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import FormHelperText from '@material-ui/core/FormHelperText'
import actions from '../../services/authorization-actions'
import { IMAGE_ALT, TOKENS } from '../../../../services/constants'
import { HttpClient } from '../../../../services/HttpClient'
import { getCookie } from '../../services/authorization-service'
import rootActions from '../../../../services/root/root-actions'

const DOCUMENT_TITLE = 'R-Zero'

const LogInPageComponent = (props) => {
  const {
    classes,
    error,
    logIn,
    resetPassword,
    resetLoginErrors,
    resetPasswordError,
    loadConfiguration,
    hrefForOktaLogin,
    getOktaIsEnabledFlag,
    isOktaEnabled,
    isMobile,
  } = props
  const resetPasswordSending = useSelector(
    (state) => state.authorizationReducer.resetPasswordSending
  )
  const loginInProgress = useSelector(
    (state) => state.authorizationReducer.loginInProgress
  )
  const loginRef = createRef()
  const passwordRef = createRef()
  const [isLoginEmpty, setLoginEmpty] = useState(false)
  const [isPasswordEmpty, setPasswordEmpty] = useState(false)
  const [resetPasswordRequest, setResetPassword] = useState(false)
  const [requestingResetPassword, setRequestingResetPassword] = useState(false)

  const isOktaLogin = window.location.href.includes('redirectFromOkta')
  useEffect(() => {
    document.title = DOCUMENT_TITLE
  }, [])

  useEffect(() => {
    if (isOktaLogin) {
      const idToken = getCookie(TOKENS.idToken)
      const refreshToken = getCookie(TOKENS.refreshToken)
      const tokenIssuer = getCookie(TOKENS.tokenIssuer)

      HttpClient.login({ idToken, refreshToken, tokenIssuer })

      loadConfiguration()
    } else {
      getOktaIsEnabledFlag()
    }
  }, [])

  const onSubmit = () => {
    const login = loginRef.current.value
    const password = passwordRef.current.value
    setResetPassword(false)
    resetLoginErrors()

    if (login && password) {
      const body = {
        login: trim(login),
        password,
      }

      logIn(body, goToSummary)
    } else {
      setLoginEmpty(!login)
      setPasswordEmpty(!password)
    }
  }

  const forgetPassword = () => {
    resetLoginErrors()

    if (loginRef.current.value) {
      setLoginEmpty(false)
      resetPassword(loginRef.current.value, () => {
        setRequestingResetPassword(false)
        setResetPassword(true)
      })
    } else {
      setLoginEmpty(true)
    }
  }

  const startResetPasswordProcess = () => {
    setRequestingResetPassword(true)
  }

  const stopResetPasswordProcess = () => {
    setRequestingResetPassword(false)
  }

  return isOktaLogin ? (
    <div className={classes.message}>{AUTH_IS_IN_PROGRESS}</div>
  ) : (
    <div className={isMobile ? classes.mobileLogin : classes.desktopLogin}>
      <div
        className={
          isMobile ? classes.loginWrapperMobile : classes.loginWrapperDesktop
        }
      >
        <img className={classes.logoImage} src={logo} alt={IMAGE_ALT.LOGO} />
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.loginHelper}
        >
          {HELPER_TEXT.signIn}
        </Typography>
        <TextField
          id="outlined-basic"
          label={LOGIN_LABELS.email}
          required
          error={!!(error || isLoginEmpty)}
          inputRef={loginRef}
          className={classes.loginInput}
          variant="outlined"
        />
        <FormHelperText
          error
          className={
            resetPasswordRequest && isLoginEmpty
              ? classes.errorText
              : classes.helperTextInvisible
          }
        >
          {HELPER_TEXT.emailRequired}
        </FormHelperText>
        {!requestingResetPassword && (
          <>
            <TextField
              id="outlined-basic"
              label={LOGIN_LABELS.password}
              type="password"
              required
              inputRef={passwordRef}
              error={!!(error || isPasswordEmpty)}
              className={classes.loginInput}
              variant="outlined"
            />
            <FormHelperText
              error
              className={
                error || resetPasswordError
                  ? classes.errorText
                  : classes.helperTextInvisible
              }
            >
              {error || resetPasswordError || ''}
            </FormHelperText>
          </>
        )}
        <Button
          variant="contained"
          className={classes.loginButton}
          onClick={requestingResetPassword ? forgetPassword : onSubmit}
          color="primary"
          disabled={loginInProgress}
          endIcon={
            loginInProgress ? (
              <CircularProgress size={20} color="primary.contrastText" />
            ) : null
          }
        >
          {requestingResetPassword
            ? BUTTONS_LABEL.confirm
            : BUTTONS_LABEL.login}
        </Button>
        {requestingResetPassword && (
          <Button variant="outlined" onClick={stopResetPasswordProcess}>
            {BUTTONS_LABEL.cancel}
          </Button>
        )}
        {isOktaEnabled && (
          <Button
            variant="outlined"
            className={classes.loginWithOkta}
            color="primary"
            href={hrefForOktaLogin}
          >
            {BUTTONS_LABEL.loginWithOkta}
          </Button>
        )}
        {requestingResetPassword ? null : !resetPasswordRequest ? (
          <Link
            component="button"
            variant="body2"
            color="textSecondary"
            className={classes.forgotPassword}
            onClick={startResetPasswordProcess}
            disabled={resetPasswordSending}
          >
            {LINKS.forgotPassword}
          </Link>
        ) : (
          <Box bgcolor="warning.main" color="warning.contrastText" p={2}>
            <Typography variant="subtitle1">
              {HELPER_TEXT.resetPasswordEmailSent}
            </Typography>
          </Box>
        )}
      </div>
      {!isMobile && (
        <div className={classes.pictureWrapper}>
          <img
            className={classes.lampPictureWrapper}
            src={eqipment}
            alt={IMAGE_ALT.ARC_DEVICE}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    error: state.authorizationReducer.error,
    resetPasswordError: state.authorizationReducer.resetPasswordError,
    hrefForOktaLogin: state.authorizationReducer.hrefForOktaLogin,
    isOktaEnabled: false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (body, onSuccess) => dispatch(actions.login(body, onSuccess)),
    resetPassword: (login, onSuccess) =>
      dispatch(actions.resetPassword(login, onSuccess)),
    resetLoginErrors: () => dispatch(actions.resetLoginErrors()),
    loadConfiguration: () => dispatch(rootActions.loadConfiguration()),
    getOktaIsEnabledFlag: () => dispatch(actions.getOktaIsEnabledFlag()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withMediaQuery(LogInPageComponent)))
